// Describes contants for request methods
export const GET = 'GET';
export const PUT = 'PUT';
export const PATCH = 'PATCH';
export const DELETE = 'DELETE';
export const POST = 'POST';

// Describes the properties every endpoint contains
export interface Endpoint {
  displayName: string
  description: string
  method: string
  route: string
  requestBody: any
  requestExample: string
  queryParameters: any
  uriParameters: any
  responses: Response[]
  responseExample: string
  anchorId: string
  headers: any
}

// Describes an API response status
export interface Response {
  httpCode: string
  description: string
}

// Describes properties from a Query, URI or Body request parameters
export interface FieldProperties {
  name: string
  type: any
  required: boolean
  description: string
  options?: string[]
  defaultVal?: string
  minimum?: string
}

// Describes the properties on an data model for a section
export interface DataModelField {
  name: string
  type: string
  description: string
  required?: boolean
  format?: string
  readyOnly?: boolean
  options?: string[]
}
