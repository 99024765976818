import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
// Types
import { DataModelField } from './api-interface';

interface DataModelTableProps {
  list: DataModelField[]
  label: string
}

const DataModelTable: React.FC<DataModelTableProps> = ({
    list,
    label,
}: DataModelTableProps): JSX.Element => (
    <div className="object-list-wrapper">
        <h3>The {label} object</h3>
        <ul className="object-list">
            {list.map((field: DataModelField) => (
                <li className="list-item-example" key={`${field.name}${field.type}`}>
                    <div className="example-list-item-title">
                        {field.name}
                        <span>{field.type}</span>
                        {field.format && (
                            <span className="format">{`| format is ${field.format}`}</span>
                        )}
                        {field.options && (
                            <span className="options">
                                {`| options are ${field.options.join(', ')}`}
                            </span>
                        )}
                    </div>
                    <ReactMarkdown source={field.description} />
                </li>
            ))}
        </ul>
    </div>
);

export default DataModelTable;
