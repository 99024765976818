import * as React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
// Types
import {
    GET, PUT, PATCH, DELETE, POST, Endpoint,
} from './api-interface';

const getClassForMethod = (method: string) => {
    switch (method) {
    case GET:
        return 'get-request';
    case PUT:
        return 'post-request';
    case PATCH:
        return 'patch-request';
    case DELETE:
        return 'delete-request';
    case POST:
        return 'post-request';
    default:
        return '';
    }
};

interface EndpointListProps {
  endpoints: Endpoint[]
}

const EndpointList: React.FC<EndpointListProps> = ({
    endpoints,
}: EndpointListProps): JSX.Element => (
    <ul>
        {endpoints.map(
            (endpoint: Endpoint): JSX.Element => (
                <button
                    type="button"
                    onClick={() => scrollTo(`#${endpoint.anchorId}`)}
                    key={`${endpoint.method}${endpoint.route}`}
                >
                    <li>
                        <pre>
                            <code>
                                <span
                                    className={getClassForMethod(endpoint.method.toUpperCase())}
                                >
                                    {endpoint.method.toUpperCase()}
                                </span>
                                <span className="api-route">{endpoint.route}</span>
                            </code>
                        </pre>
                    </li>
                </button>
            ),
        )}
    </ul>
);

export default EndpointList;
