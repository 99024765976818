import * as React from 'react';
import { graphql } from 'gatsby';
import * as Prism from 'prismjs';
import * as ReactMarkdown from 'react-markdown';
// Types
import { Endpoint } from '../components/api/api-interface';
// Components
import EndpointList from '../components/api/endpoint-list';
import DataModelTable from '../components/api/data-model-table';
import EndpointSection from '../components/api/endpoint-section';
import SEO from '../components/seo';

// Creates the API pages for each section from /src/utils/data/platform-api
const APITemplate: React.FC<any> = ({ data }: any) => {
    React.useEffect(() => {
    // the highlightAll() function styles the code blocks
        Prism.highlightAll();
    }, []);

    // Rename api for ease of use
    const api: any = data.allPlatformApiJson.nodes[0];

    return (
        <>
            <SEO title={api.section} description={api.definition} />
            <section className="page-header" id="top">
                <h1>{api.section}</h1>
            </section>
            <div className="api-page-wrapper">
                <section className="section-definition-section">
                    <span>
                        <ReactMarkdown source={api.definition} />
                    </span>
                    <div className="endpoint-list">
                        <h4>Endpoints</h4>
                        <EndpointList endpoints={api.endpoints} />
                    </div>
                </section>

                {api.dataModel.map((model: any) => (
                    <section
                        key={model.name}
                        className="object-defn-section"
                        id={`${model.name.replace(/\s/g, '-')}`}
                    >
                        <DataModelTable
                            list={model.dataModelProperties}
                            label={model.name}
                        />
                        <div className="half-section">
                            {model.dataModelExample && (
                                <div className="object-example-wrapper">
                                    <h4>The {model.name} object</h4>{' '}
                                    <div className="code-wrapper">
                                        <pre className="language-javascript">
                                            <code className="language-javascript">
                                                {model.dataModelExample}
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            )}
                        </div>
                    </section>
                ))}

                <section className="endpoints-wrapper">
                    {api.endpoints.map(
                        (endpoint: Endpoint): JSX.Element => (
                            <EndpointSection key={endpoint.anchorId} endpoint={endpoint} />
                        ),
                    )}
                </section>
            </div>
        </>
    );
};

export const apiQuery = graphql`
  query APIPagebyPath($section: String!) {
    allPlatformApiJson(filter: { section: { eq: $section } }) {
      nodes {
        path
        section
        definition
        endpoints {
          displayName
          description
          headers {
            name
            description
            required
          }
          method
          route
          anchorId
          requestBody {
            name
            description
            required
            options
            type
          }
          requestExample
          responseExample
          responses {
            httpCode
            description
          }
          queryParameters {
            type
            required
            name
            minimum
            description
            defaultVal
          }
          uriParameters {
            type
            required
            name
            description
          }
        }
        dataModel {
          name
          dataModelExample
          dataModelProperties {
            description
            format
            mutable
            name
            options
            pattern
            required
            type
          }
        }
      }
    }
  }
`;

export default APITemplate;
