/**
 * This component is for request bodies in URI, QUERY or JSON BODY descriptions
 */
import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
// Types
import { FieldProperties } from './api-interface';

interface ParameterTableProps {
  label: string
  list: FieldProperties[]
}

const ParameterTable: React.FC<ParameterTableProps> = ({
    label,
    list,
}: ParameterTableProps): JSX.Element => (
    <div className="table-wrapper">
        <h5>{label}</h5>
        <ul>
            {list.map((field: FieldProperties) => (
                <li className="body-list-item" key={`${field.name}${field.type}`}>
                    <div className="body-field-attributes">
                        <p className="body-field-name">{field.name}</p>
                        <p className="body-field-type">{field.type}</p>
                        <p className="body-field-required">
                            {field.required ? 'required' : ''}
                        </p>
                    </div>
                    <div className="body-field-description">
                        <ReactMarkdown source={field.description} />
                        <span className="body-field-misc">
                            {field.options && (<p>Options are {field.options.join(', ')}</p>)}
                            {field.defaultVal && (<p>Default value is <code>{field.defaultVal}</code></p>)}
                            {field.minimum && (<p>Minimum value is <code>{field.minimum}</code></p>)}
                        </span>
                    </div>
                </li>
            ))}
        </ul>
    </div>
);

export default ParameterTable;
