import * as React from 'react';
import * as ReactMarkdown from 'react-markdown';
// Types
import {Endpoint, FieldProperties, Response} from './api-interface';
// Components
import ParameterTable from './parameter-table';
import APIExample from './api-example';
import LocalizedLink from '../links/localizedLink';

interface EndpointSectionProps {
  endpoint: Endpoint
}

const EndpointSection: React.FC<EndpointSectionProps> = ({
    endpoint,
}: EndpointSectionProps): JSX.Element => (
    <div id={endpoint.anchorId} className="single-endpoint-section-wrapper">
        <h3>{endpoint.displayName}</h3>
        <div className="endpoint-body-wrapper">
            <div className="half-page">
                <div className="endpoint-subsection-wrapper">
                    <h5>Endpoint</h5>
                    <pre>
                        <code className="language-javascript">
                            {endpoint.method} {endpoint.route}
                        </code>
                    </pre>
                </div>
                <div className="endpoint-subsection-wrapper endpoint-description">
                    <h5>Description</h5>
                    <ReactMarkdown source={endpoint.description} />
                </div>
                {endpoint.uriParameters.length > 0 && (
                    <ParameterTable
                        label="Path Parameters"
                        list={endpoint.uriParameters}
                    />
                )}
                {endpoint.queryParameters.length > 0 && (
                    <ParameterTable
                        label="Query Parameters"
                        list={endpoint.queryParameters}
                    />
                )}
                {endpoint.headers.length > 0 && (
                    <div className="table-wrapper">
                        <h5>Request Headers
                            <span>
                                <LocalizedLink to="/platform/api-info/02-making-requests">{' '} What&apos;s this?</LocalizedLink>
                            </span>
                        </h5>
                        <ul>
                            {endpoint.headers.map((field: FieldProperties) =>(
                                <li className="body-list-item" key={`${field.name}`}>
                                    <div className="body-field-attributes">
                                        <p className="body-field-name">{field.name}</p>
                                        <p className="body-field-required">
                                            {field.required ? 'required' : ''}
                                        </p>
                                    </div>
                                    <div className="body-field-description">
                                        <ReactMarkdown source={field.description} />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                {endpoint.requestBody.length > 0 && (
                    <ParameterTable label="Request Body" list={endpoint.requestBody} />
                )}
                {endpoint.responses.length > 0 && (
                    <div>
                        <h5>Response</h5>
                        <ul>
                            {endpoint.responses.map((response: Response) => (
                                <li
                                    className="response-line-item"
                                    key={`${response.httpCode}${response.description}`}
                                >
                                    <span className="response-code">{response.httpCode}</span>
                                    <span>{response.description}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
            <div className="half-page">
                {endpoint.requestExample && (
                    <APIExample
                        header="Request Body Sample"
                        example={endpoint.requestExample}
                    />
                )}
                {endpoint.responseExample && (
                    <APIExample
                        header="Response Body Sample"
                        example={endpoint.responseExample}
                    />
                )}
            </div>
        </div>
    </div>
);

export default EndpointSection;
