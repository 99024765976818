import * as React from 'react';

interface APIExampleProps {
  header: string
  example: string
}

const APIExample: React.FC<APIExampleProps> = ({
    header,
    example,
}: APIExampleProps): JSX.Element => (
    <div className="example-wrapper">
        <h4>{header}</h4>
        <pre>
            <code className="language-javascript">{example}</code>
        </pre>
    </div>
);

export default APIExample;
